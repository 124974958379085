//Libs
import React from "react";

import Auth from "./routes/Auth/index";

class App extends React.Component {
  render() {
    return (
      <div className={"cont"}>
        <Auth />
      </div>
    );
  }
}

export default App;
