import React, { useEffect } from "react";
import { Button, Form, Layout } from "antd";
import { PasswordInput, TextInput } from "../../components/Inputs";
import { decode } from "jsonwebtoken";
import { post } from "../../api";
import useForm from "../../hooks/useForm";
import LogoCentered from "../../components/LogoCentered";
import NeedHelp from "../../components/NeedHelp.js";

const text = {
  title:
    "Por favor completar los siguientes datos para comenzar a utilizar nuestra plataforma.",
};

const FinishRegistration = ({ match }) => {
  const { formState, onChange, handleSubmit, errors } = useForm({}, [
    {
      name: "email",
      required: true,
      type: "email",
    },
    {
      name: "password",
      required: true,
      type: "password",
    },
  ]);
  const { entity, submitted, submitting } = formState;

  const onSubmit = async () => {
    await post("auth/register", entity);
    window.location.replace("https://app.inmatics.io");
  };

  useEffect(() => {
    const { jwt } = match.params;
    onChange("email", decode(jwt).email);
    // eslint-disable-next-line
  }, [match.params]);

  return (
    <Layout className={"login-container"}>
      <Form
        layout={"vertical"}
        className={"login-form card"}
        onFinish={handleSubmit(onSubmit)}
      >
        <LogoCentered />
        <h1>{text.title}</h1>

        <TextInput
          onChange={onChange}
          label={"Nombre *"}
          placeholder={"Ingresa tu nombre"}
          entity={entity}
          submitted={submitted}
          error={errors["firstName"]}
          name={"firstName"}
        />
        <TextInput
          onChange={onChange}
          label={"Apellido *"}
          placeholder={"Ingresa tu apellido"}
          entity={entity}
          submitted={submitted}
          error={errors["lastName"]}
          name={"lastName"}
        />
        <PasswordInput
          onChange={onChange}
          label={"Crea una Contraseña *"}
          placeholder={"Elegí una contraseña"}
          submitted={submitted}
          error={errors["password"]}
          entity={entity}
          name={"password"}
        />
        <div className={"login-form-buttons"}>
          <NeedHelp />
          <Button
            type="primary"
            disabled={submitting}
            htmlType={"submit"}
            loading={submitting}
            onClick={handleSubmit(onSubmit)}
          >
            ENVIAR
          </Button>
        </div>
      </Form>
    </Layout>
  );
};

export default FinishRegistration;
