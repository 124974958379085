import React from "react";
import { Alert, Button, Form, Layout } from "antd";
import { paths } from "../../constants";
import { Link } from "react-router-dom";
import { post } from "../../api";
import { TextInput } from "../../components/Inputs";
import useForm from "../../hooks/useForm";
import LogoCentered from "../../components/LogoCentered";

const FormLayoutDemo = () => {
  const { formState, onChange, handleSubmit, errors } = useForm({}, [
    {
      name: "email",
      label: "Correo Electrónico",
      required: true,
      type: "email",
      prefixIcon: "user",
    },
  ]);

  const onSubmit = async () => {
    await post("auth/send-email-recovery", { email: entity.email });
  };

  const {
    entity,
    submitted,
    submitting,
    asyncError,
    submittedSuccess,
  } = formState;

  return (
    <div style={{ display: "flex" }}>
      <div style={{ width: "100%" }}>
        <Layout className={"login-container"}>
          {submittedSuccess ? (
            <Form
              layout={"vertical"}
              className={"login-form card"}
              onFinish={handleSubmit(onSubmit)}
            >
              <LogoCentered />
              <h1 style={{ fontSize: "1.3rem" }}>
                Te enviamos un email a {entity.email}
              </h1>
            </Form>
          ) : (
            <Form
              layout={"vertical"}
              className={"login-form card"}
              onSubmit={handleSubmit(onSubmit)}
            >
              <LogoCentered />
              <h1 style={{ fontSize: "1.7rem" }}>
                Obtené una contraseña nueva
              </h1>
              {asyncError && (
                <Alert message={asyncError.message} type="error" />
              )}

              <TextInput
                onChange={onChange}
                submitted={submitted}
                error={errors["email"]}
                placeholder={
                  "Ingresá la dirección de email que usaste al registrarte"
                }
                entity={entity}
                name={"email"}
              />

              <div className={"login-form-buttons"}>
                <Button
                  style={{
                    background: "linear-gradient(221.95deg,#39cfc9,#6dc36a)",
                    color: "#FFF",
                    width: "100%",
                  }}
                  htmlType={"submit"}
                  onClick={handleSubmit(onSubmit)}
                  disabled={submitting}
                  loading={submitting}
                >
                  ENVIAR
                </Button>
              </div>
              <div>
                <small>
                  <Link to={paths.login}>Volver Iniciar sesión</Link>
                </small>
              </div>
            </Form>
          )}
        </Layout>
      </div>
      <div id={"imageSection"} style={{ width: "100%" }}>
        <img
          style={{
            width: "100%",
            height: "100%",
            justifyContent: "center",
            objectFit: "contain",
          }}
          src={"https://static.inmatics.io/backgrounds/parking.jpg"}
          alt={"parking"}
        />
      </div>
    </div>
  );
};

export default FormLayoutDemo;
