import _RISK_PARAMETERS_FIELDS from "./riskParametersFields";
import _EVALUATE_LEAD_FIELDS from "./evaluateLeadFields";

export const FIELD_TYPES = {
  EMAIL: "email",
  TEXT: "text",
  DATE: "date",
  SELECT: "select",
  NUMBER: "number",
  CURRENCY: "currency",
  CURRENCY_UVA: "currencyUVA",
  PASSWORD: "password",
  PERCENTAGE: "percentage",
  RELATIVE_TIME: "relative_time",
  RADIO_BUTTON: "radioButton",
  CHECKBOX: "checkbox",
  CAR_PICKER: "carPicker"
};

export const RISK_PARAMETERS_FIELDS = _RISK_PARAMETERS_FIELDS;
export const EVALUATE_LEAD_FIELDS = _EVALUATE_LEAD_FIELDS;
