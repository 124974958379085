import _validator from "./validator";
import _format from "./format";

export const validator = _validator;
export const format = _format;

export const getByKey = (collection, value, key = "_id") => {
  if (!collection) return null;

  const item = collection.filter((item) => item[key] === value);
  if (item) return item[0];
  return null;
};

export const sortAlphabetically = (a, b) => {
  if (a.label < b.label) {
    return -1;
  }
  if (a.label > b.label) {
    return 1;
  }
  return 0;
};
