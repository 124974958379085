import { useCallback, useReducer } from "react";
import { validator } from "../helpers";
import { isEmpty } from "lodash";

const formReducer = (state, action) => {
  switch (action.type) {
    case "SUBMIT_INIT":
      return {
        ...state,
        submitting: true,
      };
    case "SUBMIT_ATTEMPTED":
      return {
        ...state,
        submitted: true,
      };
    case "SUBMIT_SUCCESS":
      return {
        ...state,
        submittedSuccess: true,
        submitting: false,
      };
    case "SUBMIT_FAILURE":
      return {
        ...state,
        submitting: false,
        asyncError: action.payload.error,
      };
    case "ENTITY_CHANGED":
      return {
        ...state,
        entity: { ...state.entity, [action.payload.key]: action.payload.value },
      };

    case "SET_ENTITY":
      return {
        ...state,
        entity: { ...state.entity, ...action.payload.entity },
      };
    default:
      throw new Error();
  }
};

export default (initialValues = {}, fields = []) => {
  const [state, dispatch] = useReducer(formReducer, {
    submitting: false,
    submitted: false,
    entity: initialValues,
  });

  const { entity } = state;

  const handleSubmit = useCallback(
    (callback) => async (e) => {
      if (e && e.preventDefault) {
        e.preventDefault();
        e.persist();
      }

      dispatch({ type: "SUBMIT_ATTEMPTED" });
      try {
        if (isEmpty(validator(entity, fields))) {
          dispatch({ type: "SUBMIT_INIT" });
          await callback();
          dispatch({ type: "SUBMIT_SUCCESS" });
        }
      } catch (e) {
        console.log(e);
        if (e.response && e.response.status) {
          dispatch({
            type: "SUBMIT_FAILURE",
            payload: {
              error: {
                code: e.response.status,
                message: e.response.data,
              },
            },
          });
        } else {
          dispatch({
            type: "SUBMIT_FAILURE",
            payload: {
              error: { message: "Lo lamentamos mucho, algo salio mal." },
            },
          });
        }
      }
    },
    [entity]
  );

  let onChange = (key, value) =>
    dispatch({ type: "ENTITY_CHANGED", payload: { key, value } });

  let setEntity = (entity) =>
    dispatch({ type: "SET_ENTITY", payload: { entity } });

  return {
    formState: state,
    onChange,
    handleSubmit,
    setEntity,
    errors: validator(entity, fields),
  };
};
