import { formatNumber } from 'accounting';
import { FIELD_TYPES } from '../constants/forms';

import _ from 'lodash';

export const validateEmail = string => /\S+@\S+\.\S+/.test(string);
export const validateText = string =>
    /^[ÁáÉéÍíÓóÚúÑñA-Za-z _]*[ÁáÉéÍíÓóÚúÑñA-Za-z][ñA-Za-z _]*$/.test(string);
const getRequiredFields = fields => fields.filter(x => x.required);
const getEmailFields = fields =>
    fields.filter(x => x.type === FIELD_TYPES.EMAIL);
const getNumberFields = fields =>
    fields.filter(x => x.type === 'number' || x.type === 'currency');
const getTextFields = fields =>
    fields.filter(
        x => x.type === undefined || x.type === 'text' || x.type === 'password'
    );

const format = value => formatNumber(value, 0, '.');

export default (entity, fields) => {
  let errors = {};

  getRequiredFields(fields).forEach(field => {
    if (_.isNil(entity[field.name]) || entity[field.name] === '') {
      errors[field.name] = field.error || 'El campo es obligatorio';
    }
  });

  getEmailFields(fields).forEach(field => {
    if (entity[field.name] && !validateEmail(entity[field.name])) {
      errors[field.name] = 'Formato Incorrecto';
    }
  });

  getNumberFields(fields).forEach(field => {
    if (field.max && entity[field.name] > field.max) {
      errors[field.name] = `Valor máximo ${format(field.max)}`;
    } else if (field.min && entity[field.name] < field.min) {
      errors[field.name] = `Valor mínimo ${format(field.min)}`;
    }
  });

  getTextFields(fields).forEach(field => {
    if (entity[field.name]) {
      if (field.letterOnly && !validateText(entity[field.name])) {
        errors[field.name] = 'Solo se permiten letras';
      } else if (field.max && entity[field.name].length > field.max) {
        errors[field.name] = `Máximo ${field.max.toString()} caracteres`;
      } else if (field.min && entity[field.name].length < field.min) {
        errors[field.name] = `Mínimo ${field.min.toString()} caracteres`;
      } else if (field.noSpaces && entity[field.name].indexOf(' ') > 0) {
        errors[field.name] = 'No se permiten espacios';
      }
    }
  });

  return errors;
};
