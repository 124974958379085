export const eVEHICULO_USO_DECLARADO = [
  "PARTICULAR",
  "CARGA",
  "TRANSPORTE_TAXI",
  "TRANSPORTE_REMIS",
  "COLECTIVO_BUS"
].map(x => ({ id: x, label: x }));

export const eGENERO = ["FEMENINO", "MASCULINO"].map(x => ({
  id: x,
  label: x
}));

export const eCANAL_ORIGINACION = [
  "WEB_PRENDA_RAPIDA",
  "WEB_TAXI_RAPIDA",
  "AGENCIA",
  "CONCESIONARIO",
  "OFICINA",
  "COMERCIALIZADORA",
  "MULTIMARCA"
].map(x => ({
  id: x,
  label: x
}));

export const ePRODUCTO = [
  "PRESTAMO_PRENDARIO",
  "PRESTAMO_PRENDARIO_TAXI",
  "PRESTAMO_PERSONAL_PRENDA",
  "PRESTAMO_PERSONAL_PRENDA_TAXI",
  "PRESTAMO_CANCELATORIO_PRENDA",
  "PLAN_AHORRO_ENTREGADO",
  "PLAN_AHORRO_ADJUDICADO",
  "PLAN_AHORRO_NO_ADJUDICADO"
].map(x => ({
  id: x,
  label: x
}));

export const ePRODUCTSWithID = [
  { id: "003", label: "PRESTAMO_PRENDARIO" },
  { id: "004", label: "PRESTAMO_PRENDARIO_TAXI" },
  { id: "001", label: "PRESTAMO_PERSONAL_PRENDA" },
  { id: "002", label: "PRESTAMO_PERSONAL_PRENDA_TAXI" },
  { id: "005", label: "PRESTAMO_CANCELATORIO_PRENDA" },
  { id: "006", label: "PLAN_AHORRO_ENTREGADO" },
  { id: "007", label: "PLAN_AHORRO_ADJUDICADO" },
  { id: "008", label: "PLAN_AHORRO_NO_ADJUDICADO" }
].map(x => ({
  id: x.label,
  label: x.label
}));
