import React from "react";
import { Alert, Button, Form, Input, Layout } from "antd";
import { paths } from "../../constants";
import { Link } from "react-router-dom";
import { TextInput } from "../../components/Inputs";
import { post } from "../../api";
import useForm from "../../hooks/useForm";
import LogoCentered from "../../components/LogoCentered";

const text = { title: "Iniciar Sesión" };

const PasswordInput = ({
  label,
  placeholder,
  entity,
  name,
  onChange,
  error,
  submitted,
}) => {
  return (
    <Form.Item
      label={label}
      validateStatus={error && submitted ? "error" : "success"}
      help={submitted && error}
    >
      <Input.Password
        style={{ borderRadius: 5 }}
        placeholder={placeholder}
        value={entity[name] || ""}
        onChange={(e) => onChange(name, e.target.value)}
      />
      <small>
        <Link to={paths.passwordRecovery}>Cambiar la contraseña</Link>
      </small>
    </Form.Item>
  );
};

const Login = () => {
  const { formState, onChange, handleSubmit, errors } = useForm({}, [
    {
      name: "email",
      required: true,
      type: "email",
    },
    {
      name: "password",
      required: true,
      type: "password",
    },
  ]);
  const { entity, submitted, submitting, asyncError } = formState;

  const onSubmit = async () => {
    await post("auth/login", entity);
    window.location.replace("https://app.inmatics.io");
  };

  return (
    <div style={{ display: "flex" }}>
      <div style={{ width: "100%" }}>
        <Layout className={"login-container"}>
          <Form
            layout={"vertical"}
            onFinish={handleSubmit(onSubmit)}
            className={"login-form card"}
          >
            <LogoCentered />
            <h1
              style={{
                fontSize: "1.7rem",
                textAlign: "left",
                color: "rgb(7, 44, 79)",
              }}
            >
              {text.title}
            </h1>
            {asyncError && <Alert message={asyncError.message} type="error" />}

            <TextInput
              onChange={onChange}
              error={errors["email"]}
              submitted={submitted}
              label={"Email"}
              placeholder={"Ingresá tu dirección de email"}
              entity={entity}
              name={"email"}
            />

            <PasswordInput
              onChange={onChange}
              error={errors["password"]}
              submitted={submitted}
              label={"Contraseña"}
              placeholder={"Ingresá tu contraseña"}
              entity={entity}
              name={"password"}
            />

            <Button
              style={{
                background: "linear-gradient(221.95deg,#39cfc9,#6dc36a)",
                color: "#FFF",
                borderRadius: 5,
                width: "100%",
              }}
              htmlType={"submit"}
              disabled={submitting}
              loading={submitting}
              onClick={handleSubmit(onSubmit)}
            >
              INICIAR
            </Button>

            <div className={"login-form-buttons"}>
              <small>
                <a href="https://www.inmatics.io/empezar">
                  Crear una cuenta ahora
                </a>
              </small>
            </div>
          </Form>
        </Layout>
      </div>
      <div
        id={"imageSection"}
        style={{
          width: "100%",
          borderLeft: "1px rgba(189, 195, 199, 0.5)  solid",
        }}
      >
        <img
          style={{
            width: "100%",
            height: "100%",
            justifyContent: "center",
            objectFit: "contain",
          }}
          src={"https://static.inmatics.io/backgrounds/parking.jpg"}
          alt={"parking"}
        />
      </div>
    </div>
  );
};

export default Login;
