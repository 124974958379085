export default {
  passwordRecovery: "/auth/password-recovery",
  passwordChange: "/auth/password-change/:jwt",
  acceptInvitation: "/auth/accept-invitation/:jwt",
  finishRegistration: "/auth/terminar-registro/:jwt",
  login: "/auth/login",
  createVehicle: "/vehicles/create",
  accountPicker: "/account-picker",
  dashboard: "/:account/dashboard",
};
