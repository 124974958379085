import React from "react";

export default () => (
  <div style={{ display: "flex", justifyContent: "center", marginBottom: 30 }}>
    <img
      style={{ width: 200 }}
      src={"https://static.inmatics.io/logos/logo_horizontal.svg"}
      alt={"Tala Mobility"}
    />
  </div>
);
