import axios from "axios";

let dataServer;

if (process.env.NODE_ENV === "production") {
  dataServer = "https://api.inmatics.io";
} else {
  dataServer = "http://localapi.inmatics.io:3030";
}

export const get = (endPoint, payload) =>
  axios
    .get(`${dataServer}/${endPoint}`, { withCredentials: true })
    .then((response) => response.data);

export const post = (endPoint, payload) =>
  axios
    .post(`${dataServer}/${endPoint}`, payload, { withCredentials: true })
    .then((response) => response.data);

export const put = (endPoint, payload, id) =>
  axios
    .put(`${dataServer}/${endPoint}/${id}`, payload)
    .then((response) => response.data);

export const patch = (endPoint, payload, id) =>
  axios
    .patch(`${dataServer}/${endPoint}/${id}`, payload)
    .then((response) => response.data);

export const remove = (endPoint, payload, config) =>
  axios
    .delete(`${dataServer}/${endPoint}`, payload, config)
    .then((response) => response.data);

export const postForm = (path, params, method) => {
  method = method || "post";

  let form = document.createElement("form");
  form.setAttribute("method", method);
  form.setAttribute("action", `${dataServer}/${path}`);

  for (let key in params) {
    if (params.hasOwnProperty(key)) {
      let hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("name", key);
      hiddenField.setAttribute("value", params[key]);
      form.appendChild(hiddenField);
    }
  }

  document.body.appendChild(form);
  form.submit();
};
