import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Login from "./Login";
import Recovery from "./PasswordRecovery";
import FinishRegistration from "./FinishRegistration";
import PasswordChange from "./PasswordChange";
import AcceptInvitation from "./AcceptInvitation";
import { paths } from "../../constants";

export default () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={paths.login} render={() => <Login />} />
        <Route exact path={paths.passwordRecovery} component={Recovery} />
        <Route
          exact
          path={paths.finishRegistration}
          component={FinishRegistration}
        />
        <Route exact path={paths.passwordChange} component={PasswordChange} />
        <Route
          exact
          path={paths.acceptInvitation}
          component={AcceptInvitation}
        />
        <Redirect to="/auth/login" />
      </Switch>
    </BrowserRouter>
  );
};
