import React, { useEffect } from "react";
import { Alert, Button, Form, Layout } from "antd";
import { post } from "../../api";
import { PasswordInput } from "../../components/Inputs";
import useForm from "../../hooks/useForm";
import { decode } from "jsonwebtoken";
import LogoCentered from "../../components/LogoCentered";
import NeedHelp from "../../components/NeedHelp.js";

const text = { title: "Elegí una nueva contraseña" };
const fontSize = `${50 / text.title.length}rem`;

export default ({ match }) => {
  const { formState, onChange, handleSubmit, errors } = useForm({}, [
    {
      name: "password",
      label: "Contraseña",
      required: true,
      type: "password",
      prefixIcon: "lock",
    },
  ]);

  const { entity, submitted, submitting, asyncError } = formState;

  const onSubmit = async () => {
    await post("auth/change-password", entity);
    window.location.replace("https://app.inmatics.io");
  };

  useEffect(() => {
    const { jwt } = match.params;
    onChange("email", decode(jwt).email);
    // eslint-disable-next-line
  }, [match.params]);

  return (
    <Layout className={"login-container"}>
      <Form
        layout={"vertical"}
        onFinish={handleSubmit(onSubmit)}
        className={"login-form card"}
      >
        <LogoCentered />
        <h1 style={{ fontSize: fontSize }}>{text.title}</h1>
        {asyncError && <Alert message={asyncError.message} type="error" />}

        <PasswordInput
          error={errors["password"]}
          onChange={onChange}
          submitted={submitted}
          label={"Crea una Contraseña"}
          placeholder={"Elegí una contraseña"}
          entity={entity}
          name={"password"}
        />
        <div className={"login-form-buttons"}>
          <NeedHelp />
          <Button
            type="primary"
            htmlType="submit"
            disabled={submitting}
            loading={submitting}
            onClick={handleSubmit(onSubmit)}
          >
            CAMBIAR
          </Button>
        </div>
      </Form>
    </Layout>
  );
};
