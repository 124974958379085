import { Form, Input } from "antd";
import React from "react";

export const TextInput = ({
  label,
  placeholder,
  entity,
  name,
  onChange,
  error,
  submitted,
}) => {
  return (
    <Form.Item
      label={label}
      validateStatus={error && submitted ? "error" : "success"}
      help={submitted && error}
    >
      <Input
        placeholder={placeholder}
        value={entity[name] || ""}
        onChange={(e) => onChange(name, e.target.value)}
        style={{ borderRadius: 5 }}
      />
    </Form.Item>
  );
};

export const PasswordInput = ({
  label,
  placeholder,
  entity,
  name,
  onChange,
  error,
  submitted,
}) => {
  return (
    <Form.Item
      label={label}
      validateStatus={error && submitted ? "error" : "success"}
      help={submitted && error}
    >
      <Input.Password
        placeholder={placeholder}
        value={entity[name] || ""}
        onChange={(e) => onChange(name, e.target.value)}
      />
    </Form.Item>
  );
};
